<template>
<!-- SECTION BANNER -->
  <section class="banner" id="top">
    <div class="col-text">
      <div class="banner-text">
        <p><span class="focus"><strong>Studio de Pilates</strong></span> en ligne ou à domicile dans la région des Alpilles pour retrouver sa paix intérieure et renforcer son corps.</p>
        <div class="banner-sep-container">
          <div class="banner-sep"></div>
          <img src="./../assets/banner-icon.png" alt="banner icon" />
        </div>
        <p class="opening">Réouverture du Studio / Cours à domicile : 2024 </p>
      </div>
    </div>
    <div class="col-banner"></div>
  </section>
  <!-- SECTION COMMUNICATION -->
  <section class="main">
    <div class="title-container">
      <div class="title-sep"></div>
      <div class="title">
        <h1>La communion</h1>
         <h2>du corps et de l'esprit</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-60">
        <img src="./../assets/communication-img.png" alt="communication" class="section-img" />
      </div>
      <div class="col-40 section-text">
        <p>Prendre conscience de son corps, retrouver une harmonie, reprendre le contrôle de ses mouvements, de son souffle… </p>
        <p>La santé mentale et physique passe par une connaissance de soi et de son corps. C’est exactement ce que la méthode <span class="focus"><a href="https://www.merrithew.com/stott-pilates/warmup/fr/principles/cinq-principes-de-base" target="_blank">STOTT PILATES<sup>®</sup></a></span> propose.</p>
        <p>Cette variante de la discipline de base invite chacun à retrouver une meilleure posture au moyen d’exercices profonds et revitalisants pour le dos et l’ensemble du corps.</p>
        <p>En travaillant le Pilates en douceur, les muscles sont déliés, les articulations assouplies et le métabolisme retrouve toute sa vitalité. Retrouvez votre force, votre confiance en vous et en vos capacités séance après séance.</p>
      </div>
    </div>
  </section>
  <!-- SECTION PARTICULARITES -->
  <section class="main section-reverse">
    <div class="title-container">
      <div class="title-sep"></div>
      <div class="title">
        <h1>Les  particularités</h1>
         <h2>de la méthode STOTT PILATES<sup>®</sup></h2>
      </div>
    </div>
    <div class="row">
      <div class="col-60">
        <img src="./../assets/particularites-img.png" alt="particularites" class="section-img" />
      </div>
      <div class="col-40 section-text">
        <p>Adaptés aux débutants comme aux pratiquants confirmés, les cours de STOTT PILATES<sup>®</sup> que dispense Mindfulness Pilates Studio allient exercices de respiration, positions et machines pour un renforcement musculaire et remédier à des maux passagers et chroniques. Muscler en profondeur, restaurer la courbe naturelle du dos, la méthode STOTT PILATES<sup>®</sup> ouvre le champ des possibilités pour un corps plus ferme et un meilleur soutien des articulations.</p>
        <p>Je vous accompagne à retrouver cet équilibre qui manque souvent à notre quotidien dont le rythme a perdu son sens organique. </p>
        <p>Le temps d’une séance, nous ouvrons une parenthèse pour prendre soin de vous à l’aide d’un programme personnalisé. Les exercices de Matwork s’adressent également aux femmes enceintes et jeunes mamans qui cherchent à se réapproprier leur corps et leur métabolisme. </p>
      </div>
    </div>
  </section>
  <!--SECTION PROGRAMME -->
  <section class="section-program"  id="cours">
    <div class="program-sep-top"></div>
    <div class="main">
      <h1>Le programme</h1>
      <program></program>
    </div>
    <div class="program-sep-bottom"></div>
  </section>
  <!-- SECTION COURS -->
  <section class="section-center section-cours" >
    <div class="main">
      <h1>Les cours</h1>
      <h2>Mindfulness pilates studio par <span class="title-font">Roxane</span> </h2>
        <div class="row">
        <div class="col-60">
          <img src="./../assets/MPS_Cours_synthes3d_v1.jpg" alt="cours" class="section-img" />
        </div>
        <div class="col-40 section-text">
          <p>Diplômée de la méthode STOTT PILATES<sup>®</sup> et formée par le premier centre de formation certifié de France, <span class="focus"><a href="https://www.coursformationspilatesparis.fr" target="_blank">Biopilates</a></span>, j’ai approfondi mes connaissances en anatomie fonctionnelle, en myologie et ostéologie afin d’accompagner au mieux ceux qui voudraient retrouver leur vitalité.</p>
          <p>Au programme de mes cours, il y a avant tout de la bienveillance et du partage. Au-delà de la forme physique, je vous aide aussi à réapprendre à respirer, à reprendre le contrôle sur votre corps, ses sensations et ses émotions. De nouveau ou pour la première fois, dans l’intimité de votre domicile ou lors d’un cours de Pilates en ligne, nous travaillerons les postures et exercices fondamentaux du Pilates avec les accessoires adéquats en toute sécurité et à votre rythme. </p>
        </div>
      </div>
    </div>
  </section>
  <!-- SECTION COURS -->
  <section class="section-center main section-reverse">
    <h1>Une méthode ancestrale </h1>
    <h2>associée à une technologie de pointe </h2>
    <div class="row">
      <div class="col-60">
        <img src="./../assets/methode-img.png" alt="cours" class="section-img" />
      </div>
      <div class="col-40 section-text">
        <p>La méthode STOTT PILATES<sup>®</sup> est une traduction de millénaires d’exercices et de rituels destinés au bien-être de l’esprit comme du corps, dans le respect de votre condition. </p>
        <p>Munie d’un Reformer (Clinical Pilates<sup>TM</sup>), une machine transportable dédiée à faciliter les exercices de Pilates, renforcer vos muscles profonds, redonner du tonus et délier vos articulations. Tout en douceur et en sécurité, nous évoluons de concert, séance après séance. </p>
      </div>
    </div>
  </section>
      <div class="insta-container">
      <div class="insta-sep"></div>
    </div>
  <!--SECTION BOOK -->
  <section class="section-center section-book main" id="ebook">
    <div class="row">
      <div class="col-50 col-img">
        <img src="./../assets/bg_ebook.png" alt="book" class="section-img" />
      </div>
      <div class="col-50 section-text col-text">
        <p>e-Book</p>
        <h1>"Les abysses du Pilates <span class="copy">©</span>"</h1>
        <div class="btn" @click="displayBook = true">En savoir +</div>
      </div>
    </div>
    <div class="insta-container">
        <div class="insta-sep"></div>
        <div class="insta-item">
          <div class="insta-icon"></div>
          <a href="https://www.instagram.com/mindfulnesspilates.studio/" target="_blank" class="insta-link">@mindfulnesspilates.studio </a>
        </div>
    </div>
  </section>

  <!-- SECTION CONTACT -->
  <section class="section-center section-contact" id="contact">
    <div class="program-sep-top"></div>
    <div class="main">
      <h1>Contact</h1>
      <p>Envie de rejoindre Mindfulness Pilates Studio et retrouver une sérénité psychologique et physiologique ? <br />Je vous invite à découvrir notre programme et choisir le ou les cours qui conviennent le mieux à vos envies et vos besoins.</p>
      <contact-form></contact-form>
    </div>
  </section>

  <book-modal 
    v-if="displayBook"
    v-on:closeModal="displayBook = false"
  >
  </book-modal>

</template>

<script>
import ContactForm from "./../components/ContactForm.vue";
import Program from "./../components/Program.vue";
import BookModal from "./../components/BookModal.vue";

export default {
  name: "Home",
  components : {
    ContactForm,
    Program,
    BookModal
  },
  data() {
    return {
      displayBook : false
    }
  }

}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";

.row {
  width:100%;
  display: flex;
  align-items: center;
  margin-bottom:80px;
}

.col-60 {
  width:58%;
  margin-right: 2%;
  // display: flex;
  // justify-content: flex-start;
}

.focus a {
  text-decoration:none;
  color:$mainColor;
  font-weight: 600;
}

.focus a:hover {
  text-decoration: underline;
}

.title-font {
  font-family: 'Allison', cursive;
  font-size:80px;
}

.col-40 {
  width: 40%;
}

.col-50 {
  width:50%;
}

.banner {
  height:100vh;
  display: flex;
  align-items: center;
}

.col-text {
  width:40%;
}

.col-banner{
  width:60%;
  background-image:url('./../assets/banner-home.jpg');
  background-position: right top;
  background-repeat: no-repeat;
  background-size: contain;
  height:100vh;
}

.banner-text {
  width:75%;
  margin:0 auto;
  max-width: 450px;
}

.banner p {
  font-size:20px;
  font-weight:300;
  text-align: justify;
  line-height:1.5em;
}

.banner-sep-container {
  width:60%;
  margin:20px auto 0 auto;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .banner-sep {
//   height:1px;
//   width: 80%;
//   margin: 20px 20px 0 0;
//   background:$mainColor;
// }

.title-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin:80px 0;
}

.title-sep {
  width:58%;
  height:1px;
  background:$mainColor;
}

.title {
  width:40%;
  margin-left: 2%;
}

.section-text p {
  font-size:18px;
  margin-bottom:30px;
  line-height: 2em;
}

.section-img {
  max-width: 90%;
  height:auto;
  display: block;
}

.section-reverse .row, .section-reverse .title-container {
  flex-direction: row-reverse;
}

.section-reverse .title {
  margin-left: 0;
  margin-right: 2%;
}

.section-reverse .col-60  {
  margin-right:0;
  margin-left: 2%;
  justify-content: flex-end;
}

.section-program {
  background:$mainColor;
  background-image: url('./../assets/program-bg.png');
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 350px;
}

.program-sep-top {
  height:94px;
  width:100%;
  background-image:url('./../assets/white-sep.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;  
}

.program-sep-bottom {
  height:94px;
  width:100%;
  background-image:url('./../assets/white-sep.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: rotate(180deg); 
}

.section-program h1 {
  color:#fff;
  text-align: center;
  margin-bottom:40px;
  margin-top:40px;
}

.section-program p {
  text-align:center;
  font-size:18px;
  line-height: 2em;
}

.section-center {
  padding:90px 0;
}

.section-cours {
  background-image: url('./../assets/cours-bg.png');
  background-position: top 10px right -40px;
  background-size: 250px;
  background-repeat: no-repeat;
}

.section-center h1 {
  text-align: center;
}

.section-center h2 {
  text-align: center;
  margin-bottom:40px;
}

.section-contact {
  background-color:#ededed;
  background-image: url('./../assets/contact-bg.png');
  background-position: center right;
  background-repeat: no-repeat;
  padding:0;
  padding-bottom:80px;
}

.section-contact h1 {
  margin-top:40px;
  color:$textColor;
}

.section-contact p {
  text-align: center;
  max-width: 90%;
  width: 1000px;
  margin:20px auto 40px auto;
  font-size:18px;
  line-height: 2em;
}

.insta-container {
  display:flex;
  flex-direction: column;
  align-items: center;
  width:100%;
}

.insta-sep {
  width:500px;
  max-width: 90%;
  height:2px;
  background:$textColor;
  margin-bottom: 20px;
}

.insta-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.insta-link {
  color:$textColor;
  font-weight:600;
  font-size:20px;
  text-decoration: none;
  transition:0.5s;
}

.insta-link:hover {
  color:$mainColor;
}

.insta-icon {
  height:30px;
  width:30px;
  min-width: 30px;
  background-image: url('./../assets/insta-icon.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right:20px;
}

.section-book .col-img {
  display: flex;
  justify-content: center;
}

.section-book .col-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-book p {
  font-size:30px;
  color:$textColor;
  text-align: center;
  font-weight: 400;
  margin-bottom:0;
}

.section-book h1 {
  color:$textColor;
  font-size:60px;
  margin-bottom:20px;
}

.btn {
    background:$textColor;
    padding:5px 30px;
    border-radius:25px;
    text-decoration: none;
    font-size:15px;
    color:#fff;;
    border:1px solid $textColor;
    transition: 0.5s;
    cursor: pointer;
    margin:0 10px;
}

.btn:hover {
  background:#fff;
  color:$textColor;
}

p.opening {
  margin-top:40px;
  font-family: 'Allison', cursive;
  font-weight:200;
  font-size:40px;
  text-align: center;
}

@media  screen and (max-width:768px) {
  .title-sep {
    display: none;
  }

  .title {
    width:100%;
    margin:0;
  }

  h1 {
    font-size:35px;
    text-align: center;
  }

  h2 {
    font-size:20px;
    text-align: center;
  }

  .row {
    flex-direction: column;
    margin-bottom:40px;
  }

  .col-60, .col-40, .col-50 {
    width:100%;
    margin:0;
  }

  .section-img {
    margin-bottom:40px;
    display: block;
  }

  .section-text p {
    font-size:15px;
  }

  .section-reverse .row {
    flex-direction: column;
  }

  .title-container {
    margin:40px 0;
  }

  .section-cours, .section-center {
    padding:0;
  }

  .banner {
    flex-direction: column-reverse;
    height:auto;
  }

  .col-banner {
    width:100%;
    height:300px;
    background-position: center;
    background-size: cover;
    margin-bottom:20px;
  }

  .col-text {
    width:90%;
  }

  .banner-text {
    width:100%;
  }

  .banner p {
    font-size:18px;
  }

  .banner p.opening {
    font-size:30px;
  }

  .section-contact {
    padding-bottom:20px;
  }

  .section-contact  p {
    font-size:15px;
  }

  .section-cours {
    background-image: none;
  }

  .insta-item {
    flex-direction: column;
    align-items: center;
  }

  .insta-icon {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .section-book h1 {
    font-size:40px;
  }
}

</style>
