<template>
    <div class="modal">
        <div class="modal-container">
            <div class="close-modal" @click="$emit('closeModal')"></div>
            <div class="flower-modal">
                <img src="./../assets/program-flower.png" alt="flower" />
            </div>
            <h3 v-html="item.name"></h3>
            <p v-html="item.descFull"></p>
            <p class="infos" v-if="item.time && item.price">{{ `${item.time} - ${item.price}`}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name : "ProgramModal",
    props : ['item']
}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";

.modal {
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:999;
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#fff;
    background-image: url('./../assets/modal-bg.png');
    background-size: inherit;
    background-position: right 20px center;
    background-repeat: no-repeat;
    border-radius:20px;
    padding: 50px 100px;
    position:relative;
    width:900px;
    max-width: 90%;
}

.close-modal {
    position: absolute;
    top:15px;
    right:15px;
    height:30px;
    width:30px;
    min-width: 30px;
    background-image:url('./../assets/icon-close.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.flower-modal {
    position: absolute;
    top:-10px;
    left:-20px;
}

h3 {
    text-align:center;
    text-transform: uppercase;
    font-size:25px;
    line-height: 25px;
    font-weight:200;
    margin-bottom:40px;
}

p {
    text-align: center;
}

p.infos {
    font-size:20px;
    font-weight: 600;
    margin-top:20px;
}

@media screen  and (max-width:768px) {
    .modal-container {
        padding:20px;
        max-width: calc(90% - 40px);
        top:20px;
        max-height: calc(100vh - 200px);
        overflow-y: scroll;
    }

    .flower-modal {
        display: none;
    }

    h3 {
        font-size:20px;
    }
}
</style>