<template>
    <div class="program-item">
        <div class="program-flower" v-if="item.id === 1">
            <img src="./../assets/program-flower.png" alt="flower" />
        </div>
        <div class="program-flower-2" v-if="item.id === 3">
            <img src="./../assets/program-flower-2.png" alt="flower" />
        </div>
        <h3 v-html="item.name"></h3>
        <div class="program-sep"></div>
        <h4 v-html="item.place"></h4>
        <p class="program-desc" v-html="item.descLight"></p>
        <div class="program-infos">
            <p>{{ item.time }}</p>
            <p>{{ item.price }}</p>
        </div>
        <div class="program-actions">
            <div class="btn btn-more" @click="$emit('openModal', item)">En savoir +</div>
            <router-link to="/#contact" class="btn">Réserver</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name : "ProgramItem",
    props : ['item']
}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";
    .program-item {
        width:310px;
        padding:40px 20px;
        background:#fff;
        border-radius:10px;
        margin:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        z-index: 2;
    }

    h3 {
        text-align:center;
        text-transform: uppercase;
        font-weight:300;
        font-size:25px;
        line-height: 25px;
    }

    .program-sep {
        height:2px;
        width: 180px;
        margin:10px auto;
        background:$mainColor;
    }

    h4 {
        text-align: center;
        font-weight: 300;
        text-transform: uppercase;
        font-size:15px;
        min-height:40px;
    }

    p.program-desc {
        text-align:center;
        font-size:15px;
        margin:20px auto;
    }

    .program-infos {
        min-height:58px;
    }

    .program-infos p {
        text-align: center;
        font-size:20px;
        font-weight:600;
        margin-bottom:5px;
    }

    .program-actions {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top:20px;
    }

    .btn {
        background:$mainColor;
        padding:5px 30px;
        border-radius:25px;
        text-decoration: none;
        font-size:15px;
        color:#fff;;
        border:1px solid $mainColor;
        transition: 0.5s;
        cursor: pointer;
        margin:0 10px;
    }

    .btn:hover {
        background:#fff;
        color:$mainColor;
    }

    .btn.btn-more {
        background:$textColor;
        border-color:$textColor;
    }

    .btn.btn-more:hover {
        background:#fff;
        color:$textColor;
    }

    .program-flower {
        position: absolute;
        top:-35px;
        left:-30px;
        z-index: 5;
    }

    .program-flower-2 {
        position: absolute;
        top:20px;
        right:-92px;
        z-index: 1;
    }

    @media screen and (max-width:1350px) {
        .program-flower-2 {
            display: none;
        }
    }

    @media screen  and (max-width:768px) {
        h3 {
            font-size:20px;
        }

        .program-infos p {
            font-size:18px;
            margin-bottom: 2px;
        }

        .program-actions {
            flex-direction: column;
            align-items: center;
        }

        .btn.btn-more {
            margin-bottom:20px;
        }

        .btn {
            width:150px;
            padding:5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>