<template>
    <div class="contact-form" v-if="!loading">
          <form @submit.prevent="onSubmit">
              <template v-if="!success">
              <div class="row">
                  <div class="col-75">
                        <!-- Email -->
                        <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                            <!-- <label for="">Email *</label> -->
                            <input class="form-control" placeholder="Saisissez votre adresse email pour recevoir notre e-Book *" type="email" v-model="v$.form.email.$model">
                            <!-- error message -->
                            <template v-if="v$.form.email.$errors.length > 0">                
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'required'">Ce champs est requis</div>
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'email'">Format d'email invalide</div>
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.email.$errors[0].$params.max}} caractères</div>
                            </template>
                        </div>
                    </div>
                    <div class="col-25">
                        <button :disabled="v$.form.$invalid" class="btn">Valider</button>
                    </div>
                </div>
                
 
                </template>
                <!-- Success -->
                <div class="notices success" v-if="success">Votre message a été envoyé avec succès. Nous vous recontacterons très prochainement !</div>
                <!-- Errors -->
                <div class="notices errors" v-if="errors">{{ errors}}</div>
          </form>
    </div>
    <div class="loader-container" v-else>
        <Loader ></Loader>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, maxLength} from '@vuelidate/validators'
import axios from 'axios'
import Loader from './Loader.vue'

export default {
    name : "ContactForm",
    components : {
        Loader
    },
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form : {
                email : "",
            },
            success : false,
            errors : null,
            loading : false

        }
    },
    validations() {
        return {
            form : {
     
                email: {
                    required, email, max: maxLength(150) 
                }
            }
        }        
    },
    methods : {
        onSubmit() {
            this.loading = true
            this.errors = null
            this.success = false

            let formData = new FormData();

            formData.append('email', this.form.email);                        

            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .post('https://www.mindfulnesspilates.fr//mailer/sendmail-book.php', formData, config)
                .then(function (res) {
                    vm.loading = false;
                    if (res.status == 400) {
                        vm.errors = 'Merci de remplir tous les champs obligatoires.'
                    }else if(res.status == 500) {
                        vm.errors = 'Une erreur est survenue, veuillez réessayer ultérieurement.'
                    
                    }else if(res.status == 200) {
                            vm.success = true;
                            vm.form.email = null ;   
                        }else {
                            vm.errors = 'Une erreur est survenue, veuillez réessayer ultérieurement.'
                        }
                    }
                )     
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    form {
        width:800px;
        max-width:96%;
        margin:0 auto;
    }

    .contact-form {
        max-width:100%;
    }

    .row {
        display:flex;
        width:100%;
        align-items: center;
        max-width: 100%;
    }

    .col-75 {
        width:71%;
        margin:10px auto;
    }

    .col-25 {
        width:21%;
        margin:10px auto; 
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    label {
        font-family: 'Quicksand', sans-serif;
        color:$textColor;
        font-size:15px;
        font-weight:600;
        color:#fff;
    }

    input, textarea, select {
        appearance: none;
        padding:15px;
        border-radius:10px;
        border:1px solid $textColor;
        width:calc(100% - 30px);
        transition: 0.5s;
        font-family: 'Quicksand', sans-serif;
        color:$mainColor;
        font-weight:600;
    }

    select {
        width:100%;
    }

    option {
        color:$textColor;
        font-weight:600;
    }

    input:focus, textarea:focus, select:focus {
        outline: none;
        border-color:$mainColor;
    }

    .form-group.error input {
        border-color:red;
    }

    .form-group.error label {
        color:red;
    }

    .error-msg {
        color:red;
        font-size:12px;
        line-height:1.5em;
    }

    .row-btn {
        display: flex;
        justify-content: center;
        margin:10px;
    }

    .btn {
        background:$mainColor;
        padding:8px 25px;
        border-radius:25px;
        text-decoration: none;
        font-size:18px;
        color:#fff;;
        border:1px solid $mainColor;
        transition: 0.5s;
        cursor: pointer;
    }

    .btn:hover {
        background:#fff;
        color:$mainColor;
    }

    .btn:disabled {
        opacity: 0.5;
        cursor:not-allowed;
    }

    .notices {
        padding:10px;
        width:calc(100% - 20px);
        border-radius:10px;
        text-align:center;
        color:green;
        border:1px solid green;
        margin-top:20px;
        background: #fff;
    }

    .notices.errors {
        color:red;
        border-color:red;
    }

    .loader-container {
        height:200px;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

@media screen  and (max-width:768px) {
    .row {
        flex-direction: column;
    }

    .col-75, .col-25 {
        width:100%;

    }

    .col-25 {
        display: flex;
        justify-content: center;
    }
}


</style>
