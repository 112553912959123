<template>
    <div class="modal">
        <div class="modal-container">
            <div class="close-modal" @click="$emit('closeModal')"></div>
            <div class="flower-modal">
                <img src="./../assets/program-flower.png" alt="flower" />
            </div>
            
            <div class="row">
                <div class="col-50">
                    <img src="./../assets/ebook_400.png" alt="book" />
                </div>
                <div class="col-50">
                    <h3>Les abysses du Pilates <span class="copy">©</span></h3>
                    <p class="desc">
                        Cet e-Book est un recueil d’exercices de rééducation corporelle lors d’un deuil périnatal. 
                        Un vécu relaté ; un récit à la première personne, afin de mettre des mots sur des maux. 
                        C’était une nécessité que ces pages restent dans le temps afin de mettre en lumière une réalité douloureuse et ainsi, lever le tabou.
                        Pouvoir apporter mon aide ne serait-ce qu’à une seule femme était une évidence.  <br /><br />
                        Tout au long de votre lecture, je vous propose des exercices de la méthode Stott Pilates® qui peuvent être faits les uns à la suite des autres, ou bien séparément à différents moments, afin que le Pilates vous accompagne au mieux lors de votre rééducation et de votre prochaine grossesse.
                    </p>
                    <p class="infos"> e-Book - PDF téléchargeable - 35 pages</p>
                </div>
            </div>
            <p class="form-text">Recevoir "Les abysses du Pilates"</p>
            <book-form></book-form>
        </div>
    </div>
</template>

<script>
import BookForm from "./BookForm.vue";

export default {
    name : "BookModal",
    components : {
        BookForm
    }
}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";

.modal {
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:rgba(0,0,0,0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:999;
}

.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:#fff;
    background-image: url('./../assets/modal-bg.png');
    background-size: inherit;
    background-position: right 20px center;
    background-repeat: no-repeat;
    border-radius:20px;
    padding: 50px 100px;
    position:relative;
    width:900px;
    max-width: 90%;
}

.close-modal {
    position: absolute;
    top:15px;
    right:15px;
    height:30px;
    width:30px;
    min-width: 30px;
    background-image:url('./../assets/icon-close.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.flower-modal {
    position: absolute;
    top:-10px;
    left:-20px;
}

h3 {
    text-align:left;
    font-family: 'Allison', cursive;
    font-size:60px;
    font-weight: 200;
    line-height: 25px;
    margin-bottom:40px;
    color:$textColor;
    line-height: 60px;
}

p.desc {
    text-align: justify;
    font-style: italic;    
}

p.infos {
    font-size:18px;
    font-weight: 400;
    margin-top:20px;
    margin-bottom: 20px;
    padding-left:40px;
    text-align: left;
    position: relative;
}

p.infos:before {
    content: "";
    position: absolute;
    left:0;
    width:25px;
    height:25px;
    background: url("./../assets/banner-icon.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.row {
    display: flex;
    align-items: center;
    width:100%;
}

.form-text {
    font-size:20px;
    margin-bottom:10px;
}

img {
    max-width: 90%;
}

.col-50 {
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen  and (max-width:768px) {
    .modal-container {
        padding:40px 20px;
        max-width: calc(90% - 40px);
        top:20px;
        max-height: calc(100vh - 200px);
        overflow-y: scroll;
    }

    .flower-modal {
        display: none;
    }

    .row {
        flex-direction: column-reverse;
        max-width: 100%;
    }

    .col-50 {
        width: 100%;
    }

    h3 {
        font-size:40px;
    }
}
</style>