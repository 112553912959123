<template>
    <div class="program-container">
        <program-item 
            v-for="(item, idx) in programList"
            :key="idx"
            :item="item" 
            v-on:openModal="openModal"
        >
        </program-item>
        <program-modal
            v-if="displayModal"
            :item="itemSelected"
            v-on:closeModal="closeModal"
        ></program-modal>
    </div>
</template>

<script>
import ProgramItem from "./ProgramItem";
import ProgramModal from './ProgramModal.vue'

export default {
    name : "Program",
    components : {
        ProgramItem,
        ProgramModal
    },
    data() {
        return {
            programList : [
                {
                    id : 1,
                    name : "cours <br /><strong>À DOMICILE</strong>",
                    place : "STOTT PILATES<sup>®</sup><br />Matwork + accessoires et/ou Reformer",
                    descLight : "Situé dans le Massif des Alpilles, Mindfulness Pilates Studio propose des ateliers...",
                    descFull : "Situé dans la région des Alpilles, Mindfulness Pilates Studio propose des ateliers à domicile pour une prise en charge totalement personnalisée et sécurisée. Dans l’intimité de votre domicile, nous travaillons postures et exercices de renforcement et de relaxation nécessaires pour conserver un corps tonique et un mental équilibré. Renforcement des articulations, rééducation après une blessure, travail anti-stress, soin post et prénatal… Pour accéder à ces séances d’accompagnement exclusives et sur-mesure avec accessoires et/ou machine, veuillez me contacter via le formulaire de contact.",
                    time : "",
                    price : ""
                },
                // {
                //     id : 2,
                //     name : "cours <br /><strong>en ligne</strong>",
                //     place : "Studio en ligne<br />de stott pilates<sup>®</sup>",
                //     descLight : "Si vous aimez faire de l’exercice en groupe, les cours virtuels de Matwork STOTT PILATES<sup>®</sup> sont ...",
                //     descFull : "Si vous aimez faire de l’exercice en groupe, les cours virtuels de Matwork STOTT PILATES<sup>®</sup> sont faits pour vous. Nous utilisons la plateforme GoToMeeting afin de rendre les cours accessibles à tous. Chaque session est une séance d’entraînement pour l’ensemble du corps, intégrant parfois quelques accessoires. Ces cours de Pilates sont adaptés à tous les niveaux et pensés pour être enseignés à distance. Pour un meilleur soin et suivi, les cours en ligne de groupe sont limités à cinq participants.",
                //     time : "60 minutes",
                //     price : "15 euros / personne"
                // },
                {
                    id : 3,
                    name : "cours <br /><strong>individuel</strong><br /><strong>en ligne</strong>",
                    place : "Studio en ligne<br />de stott pilates<sup>®</sup>",
                    descLight : "Pour une totale prise en charge de vos besoins, Mindfulness Pilates Studio met à votre ...",
                    descFull : "Pour une totale prise en charge de vos besoins, Mindfulness Pilates Studio met à votre disposition des ateliers de Pilates privés et sur-mesure pour adresser vos problématiques et les résoudre. Problèmes posturaux, renforcement musculaire et articulaire, soulagement du stress, rééducations… Maintenez votre corps en mouvement et retrouvez la mobilité dont vous avez tant besoin. ",
                    time : "60 minutes",
                    price : "35 euros"
                },
                {
                    id : 4,
                    name : "cours <br /><strong>individuel<br /><strong>en ligne</strong><br />« express » </strong>",
                    place : "Studio en ligne<br />de stott pilates<sup>®</sup>",
                    descLight : "Besoin de faire le point ? De vous détendre et de vous réaligner avec votre flamme intérieure ? ...",
                    descFull : "Besoin de faire le point ? De vous détendre et de vous réaligner ? Mindfulness Pilates Studio propose des cours de Pilates individuels express centrés sur vous et vos besoins. Cette séance privée en ligne regroupe des exercices dynamiques pour améliorer votre mobilité, travailler votre souplesse, stimuler la circulation sanguine et libérer les tensions de votre corps. Une parenthèse courte mais vivifiante dans la course du quotidien.",
                    time : "30 minutes",
                    price : "20 euros"
                },
                {
                    id : 5,
                    name : "cours <br /><strong>individuel<br /><strong>en ligne</strong><br />Prénatal & Postnatal </strong>",
                    place : "Studio en ligne<br />de stott pilates<sup>®</sup>",
                    descLight : "La grossesse est un changement physique et moral que chaque femme vit différemment. Les cours de ...",
                    descFull : "La grossesse est un changement physique et moral que chaque femme vit différemment. Les cours de STOTT PILATES<sup>®</sup> visent à soulager le corps et renforcer le système de soutien abdominal pour préparer les futures mamans à l’accouchement. En soin postnatal, le Pilates est d'une grande aide pour reprendre confiance en soi et en ses capacités, regagner cette énergie si nécessaire pour l’écriture de ce nouveau chapitre de la vie. Après un accouchement par voie basse ou par césarienne, la méthode STOTT PILATES<sup>®</sup> contribue à accélérer le processus de guérison post-partum. Grâce à des exercices ciblés, Mindfulness Pilates Studio aide les femmes enceintes ou qui ont récemment accouché à maintenir l’équilibre psychique et physique dont elles ont tant besoin.",
                    time : "40 minutes",
                    price : "25 euros"
                },
            ],
            displayModal : false,
            itemSelected : null
        }
    },
    methods : {
        openModal(item) {
            this.itemSelected = item;
            this.displayModal = true;
        },
        closeModal() {
            this.itemSelected = null;
            this.displayModal = false;
        }
    }
}
</script>

<style scoped lang="scss">
@import "./../scss/variables.scss";
    .program-container {
        margin-top:40px;
        margin-bottom:40px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
    }
</style>