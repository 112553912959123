<template>
    <div class="contact-form" v-if="!loading">
          <form @submit.prevent="onSubmit">
              <template v-if="!success">
              <div class="row">
                  <div class="col-50">
                    <!-- Firstname -->
                    <div class="form-group" :class="{ error: v$.form.firstname.$errors.length }">
                        <!-- <label for="">Prénom *</label> -->
                        <input class="form-control" placeholder="Prénom *" type="text" v-model="v$.form.firstname.$model">
                        <!-- error message -->
                        <template v-if="v$.form.firstname.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.firstname.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.firstname.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div> 
                  </div>
                  <div class="col-50">
                    <!-- Lastname -->
                    <div class="form-group" :class="{ error: v$.form.lastname.$errors.length }">
                        <!-- <label for="">Nom *</label> -->
                        <input class="form-control" placeholder="Nom *" type="text" v-model="v$.form.lastname.$model">
                        <!-- error message -->
                        <template v-if="v$.form.lastname.$errors.length > 0">
                            <div class="error-msg" v-if="v$.form.lastname.$errors[0].$validator == 'required'">Ce champs est requis</div>
                            <div class="error-msg" v-if="v$.form.lastname.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.lastname.$errors[0].$params.max}} caractères</div>
                        </template>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-50">
                        <!-- Email -->
                        <div class="form-group" :class="{ error: v$.form.email.$errors.length }">
                            <!-- <label for="">Email *</label> -->
                            <input class="form-control" placeholder="Email *" type="email" v-model="v$.form.email.$model">
                            <!-- error message -->
                            <template v-if="v$.form.email.$errors.length > 0">                
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'required'">Ce champs est requis</div>
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'email'">Format d'email invalide</div>
                                <div class="error-msg" v-if="v$.form.email.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.email.$errors[0].$params.max}} caractères</div>
                            </template>
                        </div>
                    </div>
                    <div class="col-50">
                        <!-- Phone -->
                        <div class="form-group" :class="{ error: v$.form.phone.$errors.length }">
                            <!-- <label for="">Téléphone</label> -->
                            <input class="form-control" placeholder="Téléphone" type="text" v-model="v$.form.phone.$model">
                            <!-- error message -->
                            <template v-if="v$.form.phone.$errors.length > 0">
                                <div class="error-msg" v-if="v$.form.phone.$errors[0].$validator == 'max'">Ce champs ne doit pas dépasser {{v$.form.phone.$errors[0].$params.max}} caractères</div>
                            </template>
                        </div>
                    </div>
                </div>

                
                <div class="row">
                    <div class="col-100">
                        <!-- Message -->
                        <div class="form-group" :class="{ error: v$.form.message.$errors.length }">
                            <!-- <label for="">Message *</label> -->
                            <select class="form-control" v-model="v$.form.cours.$model">
                                <option value="-1">Choisissez votre cours</option>
                                <option value="COURS A DOMICILE">COURS À DOMICILE</option>
                                <!-- <option value="COURS EN GROUPE">COURS EN LIGNE</option> -->
                                <option value="COURS INDIVIDUEL">COURS INDIVIDUEL</option>
                                <option value="COURS INDIVIDUEL « EXPRESS »">COURS INDIVIDUEL « EXPRESS »</option>
                                <option value="COURS INDIVIDUEL PRÉNATAL & POSTNATAL">COURS INDIVIDUEL PRÉNATAL & POSTNATAL</option>
                            </select>
                            <!-- error message -->
                            <template v-if="v$.form.message.$errors.length > 0">
                                <div class="input-errors" v-for="(error, index) of v$.form.message.$errors" :key="index">
                                    <div class="error-msg" v-if="v$.form.cours.$errors[0].$validator == 'required'">Ce champs est requis</div>
                                     <div class="error-msg" v-if="v$.form.cours.$errors[0].$validator == 'max'">Vous devez choisir un cours</div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-100">
                        <!-- Message -->
                        <div class="form-group" :class="{ error: v$.form.message.$errors.length }">
                            <!-- <label for="">Message *</label> -->
                            <textarea class="form-control" placeholder="Message *" v-model="v$.form.message.$model" rows="5"></textarea>
                            <!-- error message -->
                            <template v-if="v$.form.message.$errors.length > 0">
                                <div class="input-errors" v-for="(error, index) of v$.form.message.$errors" :key="index">
                                    <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'required'">Ce champs est requis</div>
                                     <div class="error-msg" v-if="v$.form.message.$errors[0].$validator == 'min'">Ce champs ne doit pas dépasser {{v$.form.message.$errors[0].$params.max}} caractères</div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                
                <!-- Submit Button -->
                <div class="row-btn">
                    <button :disabled="v$.form.$invalid" class="btn">Valider</button>
                </div>
                </template>
                <!-- Success -->
                <div class="notices success" v-if="success">Votre message a été envoyé avec succès. Nous vous recontacterons très prochainement !</div>
                <!-- Errors -->
                <div class="notices errors" v-if="errors">{{ errors}}</div>
          </form>
    </div>
    <div class="loader-container" v-else>
        <Loader ></Loader>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, maxLength, minLength } from '@vuelidate/validators'
import axios from 'axios'
import Loader from './Loader.vue'

export default {
    name : "ContactForm",
    components : {
        Loader
    },
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            form : {
                firstname : "",
                lastname : "",
                email : "",
                phone : "",
                message : "",
                cours : "-1"
            },
            success : false,
            errors : null,
            loading : false

        }
    },
    validations() {
        return {
            form : {
                firstname : {
                    required, max: maxLength(150) 
                },   
                lastname : {
                    required, max: maxLength(150) 
                },       
                email: {
                    required, email, max: maxLength(150) 
                },
                phone : {
                    max: maxLength(20) 
                },   
                message : {
                    required, max: maxLength(1500) 
                },
                cours : {
                    required, min : minLength(5)
                }
            }
        }        
    },
    methods : {
        onSubmit() {
            this.loading = true
            this.errors = null
            this.success = false

            let formData = new FormData();

            formData.append('firstname', this.form.firstname)
            formData.append('lastname', this.form.lastname)
            formData.append('email', this.form.email)
            formData.append('message', this.form.message)
            formData.append('cours', this.form.cours)
            if(this.form.phone) {
                formData.append('phone', this.form.phone)
            }            


            let config = {
                validateStatus: () => true,
            };
            let vm = this

            axios
                .post('https://www.mindfulnesspilates.fr//mailer/sendmail.php', formData, config)
                .then(function (res) {
                    vm.loading = false;
                    if (res.status == 400) {
                        vm.errors = 'Merci de remplir tous les champs obligatoires.'
                    }else if(res.status == 500) {
                        vm.errors = 'Une erreur est survenue, veuillez réessayer ultérieurement.'
                    
                    }else if(res.status == 200) {
                            vm.success = true;
                            vm.form.firstname = null;
                            vm.form.lastname = null;
                            vm.form.message = null;
                            vm.form.phone = null;
                            vm.form.email = null ;   
                            vm.form.cours = "-1" ;                    
                        }else {
                            vm.errors = 'Une erreur est survenue, veuillez réessayer ultérieurement.'
                        }
                    }
                )     
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    form {
        width:800px;
        max-width:96%;
        margin:0 auto;
    }

    .row {
        display:flex;
        width:100%;
    }

    .col-50 {
        width:46%;
        margin:10px auto;
    }

    .col-100 {
        width:96%;
        margin:10px auto; 
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    label {
        font-family: 'Quicksand', sans-serif;
        color:$textColor;
        font-size:15px;
        font-weight:600;
        color:#fff;
    }

    input, textarea, select {
        appearance: none;
        padding:15px;
        border-radius:10px;
        border:1px solid #fff;
        width:calc(100% - 30px);
        transition: 0.5s;
        font-family: 'Quicksand', sans-serif;
        color:$mainColor;
        font-weight:600;
    }

    select {
        width:100%;
    }

    option {
        color:$textColor;
        font-weight:600;
    }

    input:focus, textarea:focus, select:focus {
        outline: none;
        border-color:$mainColor;
    }

    .form-group.error input {
        border-color:red;
    }

    .form-group.error label {
        color:red;
    }

    .error-msg {
        color:red;
        font-size:12px;
        line-height:1.5em;
    }

    .row-btn {
        display: flex;
        justify-content: center;
        margin:10px;
    }

    .btn {
        background:$mainColor;
        padding:8px 25px;
        border-radius:25px;
        text-decoration: none;
        font-size:18px;
        color:#fff;;
        border:1px solid $mainColor;
        transition: 0.5s;
        cursor: pointer;
    }

    .btn:hover {
        background:#fff;
        color:$mainColor;
    }

    .btn:disabled {
        opacity: 0.5;
        cursor:not-allowed;
    }

    .notices {
        padding:10px;
        width:calc(100% - 20px);
        border-radius:10px;
        text-align:center;
        color:green;
        border:1px solid green;
        margin-top:20px;
        background: #fff;
    }

    .notices.errors {
        color:red;
        border-color:red;
    }

    .loader-container {
        height:200px;
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

@media screen  and (max-width:768px) {
    .row {
        flex-direction: column;
    }

    .col-50, .col-100 {
        width:100%
    }
}


</style>
